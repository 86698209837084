<template>
  <div id="stuanswer">
    <div class="right">
      <div class="title">
        <div class="left_title">
          <div class="img">
            <img src="" alt="" />
          </div>
          <b>课程数据统计</b>
        </div>
        <div class="right_title">导出数据</div>
      </div>
      <div class="right_info">
        <table>
          <tr>
            <th>序号</th>
            <th>试题类型</th>
            <th>试题题干</th>
            <th>试题答案</th>
            <th>参与试题人数</th>
            <th>我的答案</th>
            <th>易错选项</th>
          </tr>
          <tr v-for="(item, index) in stuanswers" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.question.type == 0 ? "单选题" : "判断题" }}</td>
            <td v-html="item.question.question"></td>
            <td>{{ item.question.answer }}</td>
            <td>42</td>
            <td>{{ item.select }}</td>
            <td>B</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
// import { util } from 'echarts';
import * as util from "@/unit/network";
export default {
  data() {
    return {
      stuanswers: "",
    };
  },
  mounted() {
    util.post(
      "/data_answer_info",
      {
        uid: this.$route.query.uid,
        csid: this.$route.query.csid,
      },
      (res) => {
        console.log(res);
        if (res.code == 1) {
          this.stuanswers = res.data;
        }
      }
    );
  },
  methods: {},
};
</script>
<style lang="less" scoped>
#stuanswer {
  width: 100%;
  height: 100%;
  display: flex;

  .right {
    // display: flex;
    width: 100%;
    .title {
      height: 72px;
      padding: 19px 34px 19px 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cdcdcd;
      .left_title {
        display: flex;
        align-items: center;
        color: #131313;
        font-weight: 500;
        .img {
          width: 26px;
          height: 27px;
          margin-right: 9px;
        }
      }
      .right_title {
        color: #fff;
        width: 100px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: #1c5eff;
        border-radius: 5px;
      }
    }
    .right_info {
      height: calc(100% - 72px);
      background: #fff;
      overflow: hidden;
      table {
        width: 1360px;
        margin-top: 5px;
        margin-left: 65px;
        border-collapse: collapse;
        border: 1px solid #bebec0;
        font-size: 16px;
        th {
          font-weight: 400;
          background-color: #e9ebee;
          height: 52px;
        }
        td,
        th {
          border-collapse: collapse;
          border: 1px solid #bebec0;
        }
        td {
          height: 80px;
          text-align: center;
        }
      }
    }
  }
}
.active {
  background-color: #1c5eff;
}
</style>